<template>
  <div>
    <span class="title"> 设备详情页 </span>
    <div class="contents">
      <div
        v-show="
          func.getRoot(
            dataList.rootList.find(el => el.name === '基本报表信息').value
          )
        "
      >
        <p class="card_item_title">基本报表信息</p>
        <el-divider></el-divider>
        <div class="card card_basic_table">
          <el-row>
            <el-col :span="24">
              <div class="card_basic_table_top_left"></div>
            </el-col>
          </el-row>
          <el-col :span="24">
            <div class="card_basic_table_contents">
              <div class="card_basic_table_contents_item">
                <p class="card_basic_table_contents_title">
                  设备编码：{{ basics.deviceCode }}
                </p>
                <p class="card_basic_table_contents_title">
                  设备型号码：{{ basics.deviceFeatureName }}
                </p>
                <p class="card_basic_table_contents_title">
                  资产编码：{{ basics.assetCode }}
                </p>
                <p class="card_basic_table_contents_title">
                  设备类型：{{ basics.deviceType }}
                </p>
              </div>
              <div class="card_basic_table_contents_item">
                <p class="card_basic_table_contents_title">
                  设备状态：{{ basics.status | findName(dict.status) }}
                </p>
                <p class="card_basic_table_contents_title">
                  更新时间：{{ basics.modifyTimeForShow }}
                </p>
                <p class="card_basic_table_contents_title">
                  电源状态：{{ basics.powerStatus }}
                </p>
              </div>
              <div class="card_basic_table_contents_item">
                <!--                            <p class="card_basic_table_contents_title">蓝牙信息：111111111</p>-->
                <p class="card_basic_table_contents_title">
                  开门次数：{{ basics.openDoorNum }}
                </p>
                <p class="card_basic_table_contents_title">
                  日累加开关门次数：{{ basics.openDoorNumTotal }}
                </p>
                <p class="card_basic_table_contents_title">
                  销量：{{ basics.salesVolume }}
                </p>
              </div>
              <div class="card_basic_table_contents_item">
                <p class="card_basic_table_contents_title">
                  客流量：{{ basics.visitorFlow }}
                </p>
                <p class="card_basic_table_contents_title">
                  日累加客流量：{{ basics.visitorFlowTotal }}{{ basics.purity }}
                </p>
                <div class="card_basic_table_contents_title" v-if="basics.purity == null">
                  纯净度(%)：{{ basics.purity }}
                </div>
                <div class="card_basic_table_contents_title" v-else>
                  纯净度(%)：{{ basics.purity }}   <span @click="purityJump()" class="card_basic_table_contents_button">查看详情</span>
                </div>
              </div>
            </div>
          </el-col>
        </div>
      </div>
      <p class="card_item_title">温度曲线信息</p>
      <el-divider></el-divider>
      <div class="card card_basic_table">
        <div class="map_conten">
          <span style="margin-right: 10px">温度曲线:</span>
          <el-button
            size="mini"
            class="map_button import"
            @click="getTemperatureCurves(5)"
            >6小时</el-button
          >
          <el-button
            size="mini"
            class="map_button import"
            @click="getTemperatureCurves(4)"
            >12小时</el-button
          >
          <el-button
            size="mini"
            class="map_button import"
            @click="getTemperatureCurves(1)"
            >1天</el-button
          >
          <el-button
            size="mini"
            class="map_button import"
            @click="getTemperatureCurves(2)"
            >1周</el-button
          >
          <el-button
            size="mini"
            class="map_button import"
            @click="getTemperatureCurves(3)"
            >1月</el-button
          >
        </div>
        <div style="width: 100%; height: 250px" id="temperature"></div>
      </div>

      <div
        v-show="
          func.getRoot(
            dataList.rootList.find(el => el.name === '投放报表信息').value
          )
        "
      >
        <p class="card_item_title">投放报表信息</p>
        <el-divider></el-divider>
        <div class="card card_launch_table">
          <div class="card_launch_table_item">
            <p>所属客户：{{ putMessage.customerName }}</p>
            <p>投放机构：{{ putMessage.putOrg }}</p>
            <p>投放区域：{{ putMessage.putArea }}</p>
            <p>投放时间：{{ putMessage.putAreaTime }}</p>
            <p>业务代表：{{ putMessage.salesMan }}</p>
            <p>联系方式：{{ putMessage.salesManPhone }}</p>
          </div>
          <div class="card_launch_table_item">
            <p>押金：{{ putMessage.deposit }}</p>
            <p>店主：{{ putMessage.shopOwner }}</p>
            <p>联系方式：{{ putMessage.shopOwnerPhone }}</p>
            <p>门店地址：{{ putMessage.shopAddress }}</p>
            <p>合约期限：{{ putMessage.contractTime }}</p>
            <p>设备位置：{{ putMessage.deviceLocation }}</p>
          </div>
        </div>
      </div>
      <div>
        <p
          class="card_item_title"
          v-show="
            func.getRoot(
              dataList.rootList.find(el => el.name === '设备状态设置').value
            )
          "
        >
          设备状态设置
        </p>
        <el-divider v-if="storage.jurisdiction.length" />
        <div class="card state_control">
          <div
            class="device_status_setup"
            v-show="
              func.getRoot(
                dataList.rootList.find(el => el.name === '设备状态设置').value
              )
            "
            v-if="storage.jurisdiction.length"
          >
            <el-form
              label-position="left"
              :model="form"
              ref="form"
              label-width="100px"
              :hide-required-asterisk="true"
            >
              <el-form-item
                :label="item.name"
                v-for="(item, index) in storage.deviceStatusSetUp"
                :key="index"
                :rules="item.rules || []"
                v-show="item.show && item.type === '1'"
                :prop="item.prop || ''"
              >
                <el-input v-model="form[item.prop]"></el-input>
                <!-- <el-input v-model="item.value"></el-input> -->
              </el-form-item>
            </el-form>
            <el-form label-position="left" label-width="100px">
              <el-form-item
                :label="item.name"
                v-for="(item, index) in storage.deviceStatusSetUp"
                :key="index"
                v-show="item.show && item.type !== '1'"
              >
                <el-switch
                  v-if="item.type === '2'"
                  :active-value="1"
                  :inactive-value="0"
                  v-model="form[item.prop]"
                  @change="
                    val => {
                      changeSwitch(val, item);
                    }
                  "
                />
                <el-slider
                  style="width: 400px; padding-bottom: 20px"
                  v-if="item.type === '3'"
                  :min="item.min"
                  :max="item.max"
                  show-input
                  :format-tooltip="item.formatTooltip"
                  v-model="form[item.prop]"
                  :marks="item.marks || {}"
                />
                <el-radio-group
                  v-if="item.type === '4'"
                  v-model="form[item.prop]"
                >
                  <el-radio
                    v-for="sitem in item.options"
                    :key="sitem.value"
                    :label="sitem.value"
                    @change="
                      val => {
                        changeSwitch(val, item);
                      }
                    "
                    >{{ sitem.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <el-button class="button button_cancel" @click="cancel"
            >取消</el-button
          >
          <el-button class="button_preserve" @click="savess">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getReportInformation,
  getTemperatureCurve,
  getPutInformation,
  deviceState,
  saves
} from "@/api/equipmentMonitoring.js";
export default {
  name: "particulars",
  data() {
    return {
      dict: {
        status: [
          { value: 1, name: "正常" },
          { value: 2, name: "维修" },
          { value: 3, name: "报废" }
        ]
      },
      frequency: 0,
      warningLow: 0,
      warning: 0,
      actionTem: 0,
      offTemValue: 0,
      thriftValue: false,
      illumination: false,
      longOff: false,
      longTem: 0,
      state1: false,
      state2: false,
      state3: false,
      state4: false,
      state5: false,
      state6: false,
      state7: false,
      state8: false,
      state9: false,
      basics: {},
      value: 1,
      value1: 1,
      mapData: [],
      mapDate: [],
      putMessage: {},
      deviceRfId: "",
      formLabelAlign: {
        name: "",
        region: "",
        type: ""
      },
      form: {
        running_mode: 1,
        actionTem: "",
        offTem: "",
        warning: "",
        warningLow: "",
        thriftValue: 0,
        illumination: 1,
        longOff: 1,
        offTemValue: 3,
        frequency: 30
      },
      storage: {
        jurisdiction: [],
        deviceStatusSetUp: [
          // {
          //   typeCode: 5,
          //   prop: "actionTem",
          //   name: "开机温度",
          //   type: "1",
          //   show: false,
          //   rules: [
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value) {
          //           /* eslint-disable */
          //           if (/^[\-\+]?\d+(\.\d{0,2})?$/.test(value)) {
          //             if (Number(value) > 50 || Number(value) < -50) {
          //               callback(new Error("最大值50，最小值-50"));
          //             } else {
          //               callback();
          //             }
          //           } else {
          //             callback(new Error("请输入正确的数字，支持两位小数"));
          //           }
          //         } else {
          //           callback();
          //         }
          //       },
          //       trigger: "blur"
          //     }
          //   ]
          // },
          // {
          //   typeCode: 6,
          //   prop: "offTem",
          //   name: "停机温度",
          //   type: "1",
          //   show: false,
          //   rules: [
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value) {
          //           if (/^[\-\+]?\d+(\.\d{0,2})?$/.test(value)) {
          //             if (Number(value) > 50 || Number(value) < -50) {
          //               callback(new Error("最大值50，最小值-50"));
          //             } else {
          //               callback();
          //             }
          //           } else {
          //             callback(new Error("请输入正确的数字，支持两位小数"));
          //           }
          //         } else {
          //           callback();
          //         }
          //       },
          //       trigger: "blur"
          //     }
          //   ]
          // },
          // {
          //   typeCode: 7,
          //   prop: "warning",
          //   name: "报警高温",
          //   type: "1",
          //   show: false,
          //   rules: [
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value) {
          //           if (/^[\-\+]?\d+(\.\d{0,2})?$/.test(value)) {
          //             if (Number(value) > 50 || Number(value) < -50) {
          //               callback(new Error("最大值50，最小值-50"));
          //             } else {
          //               callback();
          //             }
          //           } else {
          //             callback(new Error("请输入正确的数字，支持两位小数"));
          //           }
          //         } else {
          //           callback();
          //         }
          //       },
          //       trigger: "blur"
          //     }
          //   ]
          // },
          // {
          //   typeCode: 8,
          //   prop: "warningLow",
          //   name: "报警低温",
          //   type: "1",
          //   show: false,
          //   rules: [
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value) {
          //           if (/^[\-\+]?\d+(\.\d{0,2})?$/.test(value)) {
          //             if (Number(value) > 50 || Number(value) < -50) {
          //               callback(new Error("最大值50，最小值-50"));
          //             } else {
          //               callback();
          //             }
          //           } else {
          //             callback(new Error("请输入正确的数字，支持两位小数"));
          //           }
          //         } else {
          //           callback();
          //         }
          //       },
          //       trigger: "blur"
          //     }
          //   ]
          // },
          // {
          //   typeCode: 1,
          //   prop: "thriftValue",
          //   name: "节能模式",
          //   type: "2",
          //   show: false
          // },
          {
            typeCode: 2,
            prop: "illumination",
            name: "照明模式",
            type: "2",
            show: false
          },
          {
            typeCode: 9,
            prop: "longOff",
            name: "远程开关设置",
            type: "2",
            show: false
          },
          {
            typeCode: 3,
            prop: "offTemValue",
            name: "远程温度控制",
            type: "3",
            min: -50,
            max: 50,
            solo: true,
            show: false,
            marks: {
              "-50": "-50°C",
              50: "50°C"
            },
            formatTooltip: value => {
              return value + "°C";
            }
          },
          {
            typeCode: 4,
            prop: "frequency",
            name: "频率设置",
            type: "3",
            min: 0,
            max: 60,
            solo: true,
            marks: {
              0: "0分钟",
              60: "60分钟"
            },
            show: false,
            formatTooltip: value => {
              return value + "分钟";
            }
          },
          {
            typeCode: 12,
            prop: "running_mode",
            name: "模式选择",
            type: "4",
            show: false,
            //solo: true,
            options: [
              {
                label: "正常",
                value: 0
              },
              {
                label: "节能",
                value: 1
              },
              {
                label: "休眠",
                value: 2
              },
              {
                label: "强制冷",
                value: 3
              }
            ]
          }
        ]
      }
    };
  },
  created() {
    console.log(1);
    this.deviceRfId = this.$route.query.deviceRfId;
    this.getReportInformations();
    this.getPutInformations();
    this.getTemperatureCurves(6);
    this.getDeviceState();
  },
  methods: {
    cancel() {
      this.$router.push("/dashboard/equipmentMonitoring");
    },
    savess() {
      let data = {
        reqList: [],
        deviceImei: this.deviceRfId
      };
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.storage.deviceStatusSetUp.forEach(val => {
            if (this.form[val.prop] !== "" && val.show && val.solo) {
              data.reqList.push({
                typeCode: val.typeCode,
                name: val.name,
                value: this.form[val.prop]
              });
            }
          });
          saves(data).then(() => {
            this.$router.push("/dashboard/equipmentMonitoring");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取控制信息
    getDeviceState() {
      let data = { deviceImei: this.deviceRfId };
      deviceState(data).then(res => {
        let datas = res.data.data || [];
        console.log(datas, "datas");
        this.storage.jurisdiction = datas;
        datas.forEach(control => {
          const item = this.storage.deviceStatusSetUp.find(
            el => el.typeCode === control.typeCode
          );
          if (item) {
            item.show = true;
            item.name = control.name;
            if (control.value !== null) {
              this.form[item.prop] = Number(control.value);
            }
          }
        });
      });
    },
    //画图
    map() {
      let myChart = echarts.init(document.getElementById("temperature"));
      myChart.showLoading();
      let option = {
        // title: {
        //     text: '折线图堆叠'
        // },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["柜温", "环温", "机舱温度"]
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "50",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.mapDate
        },
        yAxis: {
          type: "value"
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            bottom: 20,
            backgroundColor: "#eee",
            height: 20,
            xAxisIndex: [0, 1]
          }
        ],
        series: this.mapData
      };
      myChart.hideLoading();
      myChart.setOption(option, true);
    },
    //获取投放信息
    getPutInformations() {
      let datas = { deviceImei: this.deviceRfId };
      getPutInformation(datas).then(({ data }) => {
        if (data.resultCode === 0) {
          this.putMessage = data.data;
        }
      });
    },
    changeSwitch(value, item) {
      console.log(value, item);
      let data = {
        reqList: [
          {
            typeCode: item.typeCode,
            name: item.name,
            value: value
          }
        ],
        deviceImei: this.deviceRfId
      };
      saves(data).then(({ data }) => {
        if (data.resultCode === 0) {
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success"
          });
        } else {
          this.$message({
            showClose: true,
            message: "设置失败！",
            type: "success"
          });
        }
        // this.$router.push("/dashboard/equipmentMonitoring");
      });
    },
    //获取基本信息
    getReportInformations() {
      let data = { deviceImei: this.deviceRfId };
      getReportInformation(data).then(({ data }) => {
        if (data.resultCode === 0) {
          this.basics = data.data;
        }
      });
    },
    //获取温度曲线
    getTemperatureCurves(timeType) {
      console.log(timeType, "timeType");
      this.mapData = [];
      let data = { dateType: timeType, deviceImei: this.deviceRfId };
      console.log(data, "datas");
      getTemperatureCurve(data).then(res => {
        this.mapDate = res.data.data.dateList;
        this.mapData.push(
          {
            name: "机舱温度",
            type: "line",
            data: res.data.data.cabinTmpList
          },
          {
            name: "柜温",
            type: "line",
            data: res.data.data.reeferTmpList
          },
          {
            name: "环温",
            type: "line",
            data: res.data.data.ambientTempList
          }
        );
        this.map();
      });
    },
    purityJump(){
      console.log(this.deviceRfId)
      console.log('aejaiwjei')
      this.$router.push('/dashboard/purityJump?deviceRfId='+this.deviceRfId)
    }
  }
};
</script>

<style scoped>
.button {
  margin-left: 20px;
}
.input {
  margin-top: 6px;
}
.state_control_left_input {
  display: inline-block;
  margin-bottom: 25px;
  margin-right: 55px;
}
.state_control_left_inputs {
  display: inline-block;
  margin-bottom: 25px;
  margin-right: 20px;
}
.state_control_right {
  width: 500px;
}
.state_control_left {
  margin-right: 70px;
}
.state_control_condition_input {
  width: 150px;
  margin-bottom: 20px;
}
/*.state_control_contens{*/
/*    width: 100%;*/
/*}*/
.state_control {
  /* height: 347px; */
  padding: 20px 0;
}
.state_control .device_status_setup {
  margin: 0 20px 20px 20px;
  display: flex;
  justify-content: flex-start;
}
.state_control .device_status_setup .el-form ~ .el-form {
  margin-left: 50px;
}
.map_button {
  width: 48px;
  height: 16px;
  padding: 1px !important;
  margin-top: 26px;
}
.card_launch_table_item {
  margin-top: 20px;
  margin-left: 20px;
  width: 30%;
  margin-right: 30px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #3a3a3a;
  letter-spacing: -0.04px;
  line-height: 28px;
}
.card_basic_table_contents_title {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #565d75;
  letter-spacing: 0;
  line-height: 28px;
  justify-content: space-between;
  /*margin-bottom: 2px;*/
  /* display: flex; */
}
.card_basic_table_contents_item {
  height: 166px;
  margin-left: 20px;
  margin-top: 20px;
  width: 50%;
}
.card_basic_table_contents {
  height: 129px;
  display: flex;
  justify-content: left;
}
.card_launch_table {
  height: 216px;
  display: flex;
  justify-content: left;
  margin-bottom: 32px;
}
/deep/ .el-divider--horizontal {
  margin: 10px 0 20px 0;
  border: 1px solid #ebebeb;
}
.card_item_title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  letter-spacing: -0.05px;
}
.card_basic_table_top_right {
  background: #fcfcfd;
  border: 1px solid #e0e4ec;
  border-radius: 0 3px 0 0;
  width: 100%;
  height: 66px;
}
.card_basic_table_top_left {
  width: 100%;
  height: 66px;
  opacity: 0.72;
  background: #e8eaef;
  border: 1px solid #e0e4ec;
  border-radius: 3px 0 0 0 0;
  border-radius: 3px 0px 0px 0px 0px;
}
.card_basic_table {
  margin-bottom: 32px;
  height: 295px;
}
.card {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ebecee;
  box-shadow: 0 0 15px 0 #eaebec;
  border-radius: 3px;
  border-radius: 3px;
}
.contents {
  width: 95%;
  background: #ffffff;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
  border-radius: 3px;
  padding: 35px;
}
.title {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #3a3a3a;
  letter-spacing: -0.05px;
  margin: 20px 0 24px 0;
  display: inline-block;
}
/deep/
  .device_status_setup
  .el-slider__marks
  .el-slider__marks-text:nth-last-child(1) {
  right: -50px;
}

.card_basic_table_contents_button{
  border: none;
  /* display: block; */
  font-size: 12px;
  color: blue;
}
</style>
